import React from "react"
import Layout from "./layout"
import ContactForm from "../components/contact-form"
const WeddingPackagePage = () => {


    return (

        <Layout>
            <div id="main">

                <div className="container">
                    <div className="dt-sc-hr-invisible-small  "></div>
                    <h1 className="hr-title dt-page-title">
                        <span>Wedding Packages</span>
                    </h1>
                    <div className="dt-sc-hr-invisible-small  "></div>
                    <div className="dt-sc-clear"></div>
                    <section id="primary" className="content-full-width">
                        <div className="dt-sc-hr-invisible-small  "></div>
                        <div id="post-253" className="post-253 page type-page status-publish hentry">
                            <div className="column dt-sc-two-third  first">
                                <p>Inquire about wedding packages, bridal and group makeup sessions for every special occasion</p>

                                <img className="ls-l"
                                    src="/wp-content/uploads/2016/10/Wedding-black-white.jpg" alt="" />
                                <h3>Get in Touch</h3>
                                <ContactForm />
                              
                            </div>
                            <div className="column dt-sc-one-third  ">
                                <h3>Contact Info</h3>
                                <p className="dt-sc-contact-info address"><i className="fa fa-pagelines"></i><span>25 Cherry Crest Dr, Unit #7<br />Brampton, ON L6P 3W4</span></p>
                                <div className="dt-sc-hr-invisible-small  "></div>
                                <div className="dt-sc-clear"></div>
                                <h3>Book An Appointment</h3>
                                <div className="dt-sc-appointment"><p className="dt-sc-contact-info"><i className="fa fa-phone"></i>Phone : <span><a href="tel:+19057943822">+1 905 794 3822</a></span></p></div>
                                <p>All of our services are provided by trained and experience staff, to ensure you and your party are accommodated please make appointments as necessary. Please ensure to cancel or reschedule at-least 4-5 hours in advance. Prices are subjected to change without notice. All prices do not include taxes.</p>
                                <p>&nbsp;</p>
                            </div>
                            <div className="social-bookmark"></div>
                            <div className="social-share"></div>
                        </div>


                    </section>
                </div>
            </div>

        </Layout >
    )
}

export default WeddingPackagePage
